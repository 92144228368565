import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import ContactForm from "../components/ContactForm/ContactForm"
import DienstBox from "../components/DienstBox"
import Link from "../components/HelperComponents/TransitionLink"
import ProjectBox from "../components/ProjectBox"
import SEO from "../components/seo"
import Font from "../components/styles/FontStyle"
import maxWidth from "../components/styles/maxWidth"
import WhyBox from "../components/WhyBox"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query homepage {
      prismicHomepage {
        data {
          header_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
          intro_text {
            intro_list {
              html
            }
            intro_title {
              html
            }
          }
          waarom {
            header {
              text
            }
            list {
              raw
            }
            icon {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          portfolio_one {
            uid
          }
          portfolio_two {
            uid
          }
        }
      }
      prismicBasicInfo {
        data {
          orange
          main_color_two
          main_color_three
        }
      }
      allPrismicDienstI(sort: { fields: id, order: ASC }) {
        edges {
          node {
            uid
          }
        }
      }
    }
  `)

  const {
    header_image,
    intro_text,
    waarom,
    portfolio_one,
    portfolio_two,
  } = data.prismicHomepage.data
  const { edges: dienstEdges } = data.allPrismicDienstI
  const {
    orange,
    main_color_two,
    main_color_three,
  } = data.prismicBasicInfo.data

  const introBlockStyling = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    zIndex: 9,
    backgroundColor: main_color_two,
  }

  const introStyling = {
    display: "inline-block",
    zIndex: 9,
  }

  const whyDivStyling = {
    maxWidth: maxWidth,
    margin: "0 auto",
    textAlign: "center",
  }

  return (
    <>
      <SEO title="Home" />
      <div className="borderradius-div-bottom" style={introBlockStyling}>
        <section
          style={{ ...introStyling, textAlign: "right" }}
          className="hero-text text-focus-in-left"
          dangerouslySetInnerHTML={{
            __html:
              intro_text[0].intro_title.html + intro_text[0].intro_list.html,
          }}
        />
        <section className="hero-img">
          <Img
            fluid={header_image.localFile.childImageSharp.fluid}
            alt={header_image.alt}
            loading="eager"
          />
        </section>
        <section
          style={introStyling}
          className="hero-text text-focus-in-right"
          dangerouslySetInnerHTML={{
            __html:
              intro_text[1].intro_title.html + intro_text[1].intro_list.html,
          }}
        />
      </div>

      <div id="diensten" style={{ ...whyDivStyling, maxWidth: 1200 }}>
        <h2 className="resp-header dienst-header" style={{ paddingTop: 60 }}>
          diensten
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {dienstEdges.map((n, i) => (
            <DienstBox key={i} dienstID={n.node.uid} bgColor={main_color_two} />
          ))}
        </div>
        <Link
          to="/diensten/"
          linkStyle={{
            display: "inline-block",
            color: main_color_three,
            textDecoration: "none",
            backgroundColor: main_color_two,
            padding: "20px 40px",
            margin: 15,
            fontWeight: "bold",
            borderRadius: "5px 25px 5px 5px",
          }}
        >
          bekijk alle diensten
        </Link>
      </div>
      <div id="waarom" style={whyDivStyling}>
        {waarom.map((object, i) => (
          <WhyBox key={i} object={object} flip={i % 2 === 0} />
        ))}
      </div>
      <div
        id="portfolio"
        style={{
          width: "100%",
          minHeight: 450,
          paddingBottom: 75,
          backgroundColor: main_color_two,
          textAlign: "center",
        }}
        className="borderradius-div-around"
      >
        <h2
          className="resp-header project-header-index"
          style={{ paddingTop: 60 }}
        >
          laatste projecten
        </h2>

        <section
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: 75,
          }}
        >
          <ProjectBox projectID={portfolio_one.uid} bgColor={orange} />
          <ProjectBox projectID={portfolio_two.uid} bgColor={orange} />
        </section>

        <Link
          to="/portfolio/"
          linkStyle={{
            display: "inline-block",
            color: main_color_three,
            textDecoration: "none",
            backgroundColor: orange,
            padding: "20px 40px",
            margin: 15,
            fontWeight: "bold",
            borderRadius: "5px 25px 5px 5px",
          }}
        >
          meer projecten
        </Link>
      </div>
      <ContactForm
        maxWidth={maxWidth}
        button={{
          text: main_color_three,
          background: main_color_two,
          font: Font,
        }}
      />
    </>
  )
}

export default IndexPage
