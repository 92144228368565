import React from "react"
import Img from "gatsby-image"
import { bool, object } from "prop-types"

import Ul from "./HelperComponents/PrismicUlHelper"

const WhyBox = ({ object, flip }) => {
  const img = (
    <Img
      className="resp-hide-mobile"
      fluid={object.icon.localFile.childImageSharp.fluid}
      style={{ width: "40%" }}
      key="1"
    />
  )

  const ul = <Ul key="2" list={object.list.raw} />

  const header = <h3 key="3">{object.header.text}</h3>

  const container = (
    <section
      style={{
        display: "inline-block",
        textAlign: flip ? "right" : "left",
        padding: 25,
      }}
      className="whybox"
      key="4"
    >
      {[header, ul]}
    </section>
  )

  const content = []

  if (flip) {
    content.push(container)
    content.push(img)
  } else {
    content.push(img)
    content.push(container)
  }

  return (
    <div
      className="whybox-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {content}
    </div>
  )
}

WhyBox.propTypes = {
  object: object.isRequired,
  flip: bool.isRequired,
}

export default WhyBox
